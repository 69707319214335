<template>
  <v-container class="my-16">
    <h2>Diensten</h2>
    <v-divider class="py-3"></v-divider>
    <v-row width="100%">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        v-for="employee in employees"
        :key="employee.id"
      >
        <ServiceItem
          @service_button_clicked="showServices"
          :member="employee"
          :serv="services"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceItem from "@/components/ServiceItem";
export default {
  data() {
    return {
      employees: [
        {
          id: 1,
          name: "Katten",
          img: "https://images.pexels.com/photos/1543793/pexels-photo-1543793.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenarts Specialist Chirurgie",
        },
        {
          id: 2,
          name: "Honden",
          img: "https://images.pexels.com/photos/3726314/pexels-photo-3726314.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenarts Oncologie",
        },
        {
          id: 3,
          name: "Paarden",
          img: "https://images.pexels.com/photos/634613/pexels-photo-634613.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
          expertise: "Dierenarts",
        },
        {
          id: 4,
          name: "Vogels",
          img: "https://images.pexels.com/photos/349758/hummingbird-bird-birds-349758.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenarts Cardiologie en Echografie",
        },
        {
          id: 5,
          name: "Reptielen",
          img: "https://images.pexels.com/photos/1695717/pexels-photo-1695717.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenarts Specialist Medische Beeldvorming Dipl. ECVDI",
        },
        {
          id: 6,
          name: "Knaagdieren",
          img: "https://images.pexels.com/photos/3586056/pexels-photo-3586056.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenfysiotherapeut",
        },
        {
          id: 7,
          name: "Vee",
          img: "https://images.pexels.com/photos/422218/pexels-photo-422218.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          expertise: "Dierenarts Tandheelkunde",
        },
      ],
      services: [
        {
          name: "Service A",
          animalId: "1",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service B",
          animalId: "2",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service C",
          animalId: "3",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service D",
          animalId: "4",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service E",
          animalId: "5",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service F",
          animalId: "6",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service G",
          animalId: "7",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
        {
          name: "Service H",
          animalId: "1",
          description:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga totam, magni accusantium vero iure adipisci enim aspernatur delectus quis! Ducimus!",
          bookUrl: "https://overdieren.community/group/view/1584--Over-Dieren",
        },
      ],
    };
  },
  components: {
    ServiceItem,
  },
  methods: {
    showServices(animalId) {},
  },
};
</script>

<style>
</style>