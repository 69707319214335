<template>
  <v-container>
    <SmallText />
    <ServiceList />
  </v-container>
</template>

<script>
import SmallText from "@/components/SmallText";
import ServiceList from "@/components/ServiceList";
export default {
  components: {
    SmallText,
    ServiceList,
  },
};
</script>

<style>
</style>