<template>
  <v-card>
    <v-img
      :src="member.img"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
    >
      <v-card-title v-text="member.name"></v-card-title>
    </v-img>
    <v-list three-line>
      <v-item-group>
        <v-list-item
          clickable
          tag="a"
          :href="service.bookUrl"
          v-for="service in filteredServices"
          :key="service.id"
          style=""
        >
          <v-list-item-content left>
            <v-list-item-title>{{ service.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              service.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="accent">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      services: this.serv,
    };
  },
  props: {
    member: Object,
    serv: Array,
  },
  // created() {
  //   console.log(this.serv);
  // },
  methods: {},
  computed: {
    filteredServices: function () {
      return this.services.filter(
        (service) => service.animalId == this.member.id
      );
    },
  },
};
</script>

<style>
</style>